<!--
 * @Descripttion:
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-05-29 14:29:11
-->
<template>
  <div>
    <van-nav-bar title="鸣谢" left-arrow @click-left="$router.push('/thanks')"/>

    <div class="pad4 org">
      <img :src="logo(org.logo)">
      <div>
        <h3>{{ org.name }} <label>{{ org.tag }}</label><i class="icon-go"></i></h3>
        <p><i class="icon-org"></i>{{ org.orgName }}</p>
      </div>
    </div>
    <div class="bg"></div>
    <div class="pad4 info">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis ratione repudiandae id deleniti optio illo a eligendi? Ullam veritatis molestiae nulla, quis laboriosam voluptatem minus earum laborum modi, autem atque commodi at? Magnam minus praesentium corporis similique dolorem. Modi ipsam obcaecati hic id nisi iure temporibus corrupti pariatur qui aspernatur quibusdam eligendi praesentium doloremque dicta aliquam voluptatum veniam itaque reprehenderit eius natus officiis, tempora excepturi exercitationem magnam? Provident numquam tenetur sint, delectus velit saepe, pariatur fuga harum error dolore beatae corrupti neque atque alias a, voluptas dolorum reiciendis aspernatur ea? Laboriosam dolores inventore nostrum eum expedita repellendus! Vero, officiis reiciendis distinctio sequi soluta a corrupti sapiente sed sit nisi nesciunt odit voluptatibus totam temporibus expedita labore modi error quo quasi! Laborum, nesciunt voluptatum. Dolorem cum totam nostrum eligendi? Autem corrupti hic rerum voluptatem dolore, doloribus aperiam, provident quos quia doloremque porro omnis non illo error modi, ab a odit quasi dignissimos nulla totam nemo tenetur quisquam. Fugit soluta velit natus repudiandae vel culpa quia ut saepe aut suscipit doloremque consequuntur reprehenderit, officiis ipsa sit inventore non eius odio corrupti neque maiores assumenda nisi? Iusto saepe doloremque nam praesentium tempora unde id, libero fuga eveniet fugit reprehenderit tenetur minima, deserunt nostrum.
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      org: {}
    }
  },

  beforeMount(){
    if(this.$route.params.org)
      this.org = this.$route.params.org
    else
      this.$router.push('/thanks')
  },

  computed:{
    logo(){
      return logo => logo? logo : '/static/img/icon/logo.png'
    }
  },
}
</script>

<style scoped>
  .pad4 {padding: .43rem;}
  .org {
    position: relative;
    color: hsl(0, 0%, 12%);
    display: flex;
    background-color: #fff;
  }
  .org img {width: 1.17rem; height: 1.17rem; object-fit: cover;}
  .org >div {padding-left: .27rem;}
  .org h3 {font-size: .43rem; line-height: .59rem; display: flex; align-items: center; }
  .org h3 >label {display:inline-block;margin-left:.32rem; padding: 0 .21rem; color: #00CCB2; font-size: .27rem; line-height: .48rem; background-color: #E1FFFB;}
  .org p {font-size: .35rem; line-height: .48rem; margin-top: .11rem; display: flex; align-items: center;}
  .org p >i {width: .43rem; height: .43rem;margin-right: .13rem;}
  .bg{height: .32rem;}
  .info{font-size: .4rem; line-height: .56rem;}
</style>